<template>
	<div class="position-fixed-full popup-half">
		<div class="bg-layer z-index-bg"></div>
		<div class="position-absolute full-width z-index-contents " style="bottom: 0;left: 0">
			<div
				class="ma-auto bg-white top-cover"
				:class="is_ready ? 'slide-up' : 'slide-down'"
				style="max-width: 480px"
			>
				<div class="justify-space-between under-line pa-10">
					<h6>{{ title }}</h6>
					<button
						@click="$emit('cancel')"
					><v-icon>mdi-close-circle</v-icon></button>
				</div>
				<div class="pa-10 overflow-y-auto" style="max-height: 250px;">
					<slot name="item"></slot>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'PopupHalf'
		, props: ['user', 'title']
		, data: function(){
			return {
				is_ready: false
			}
		}
		, created() {
			setTimeout( () => {
				this.is_ready = true
			}, 0)
		}
	}
</script>

<style>
	.top-cover {
		transform: translateY(100%) ;
	}
	.slide-up {
		transform: translateY(0%) !important;
		transition: all .5s .1s;
	}
	.slide-down{
		transform: translateY(100%) !important;
		transition: all .5s .1s;
	}
</style>