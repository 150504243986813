<template>
	<div class="flex-column full-height bg-white position-relative">
		<div class=" flex-row justify-space-between items-center under-line">
			<div class="flex-1 font-weight-bold">
				<input v-model="item_search.keyword" class=" pa-5-10 flex-1" placeholder="휴대폰 번호" @keyup.enter="getSearch(1)" type="number" :rules="$rules.max(item_search, 'keyword', 12)"/>
			</div>
			<button
				class=" btn-inline btn-success"

				@click="getSearch(1)"
			>검색</button>
		</div>

		<div class="full-height bg-gray-light overflow-y-auto">
			<ul class="mt-10 ">
				<li
					v-for="(item_send, s_index) in items_send"
					:key="'send_' + s_index"
					class="bg-white mb-10"
				>
					<div class="flex-row justify-space-between items-center under-line pa-10">
						<div class="flex-1 font-weight-bold">{{ item_send.shop_name }}</div>
						<button class="" @click="removeItem(s_index)"><v-icon>mdi-close-circle</v-icon></button>
					</div>
					<div class="pa-10">
						<div class=" flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">송금 금액</div>
							<div class="flex-2 ">
								<div class=" justify-space-between ">
									<input v-model="item_send.amount" class=" box flex-1 pa-10" placeholder="금액을 입력하세요" maxlength="8" :rules="[$rules.max(item_send, 'amount', 8)]" type="number" @focus="setLast(item_send)"/>
								</div>
							</div>
						</div>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">메모</div>
							<div class="flex-2"><input v-model="item_send.memo" class="input-box " placeholder="메모를 입력하세요" maxlength="20" /></div>
						</div>
					</div>
				</li>
			</ul>
		</div>

		<div
			class="text-center"
		>
			<div class="pa-10">{{ total_amount | makeComma}} / <span class="font-weight-bold">{{ shop_balance | makeComma }}원</span> </div>
			<div></div>
			<button
				class="btn btn-primary"
				:disabled="is_disabled"
				
				@click="onPin"
			>송금하기</button>
		</div>

		<PopupHalf
			v-if="is_on_half"
			@cancel="is_on_half = false"
			title="송금 대상 조회"
		>

			<template
				v-slot:item
			>
				<ul
					v-if="items_shop.length > 0"
				>
					<li
						v-for="(shop, s_index) in items_shop"
						:key="'shop_' + s_index"
						@click="setItem(shop)"
						class="pa-10 box mb-10 bg-gray-light"
					>
						{{ shop.shop_name }}
					</li>
				</ul>
				<empty
					v-else
				></empty>
			</template>
		</PopupHalf>
	</div>
</template>

<script>
	import Empty from "../Layout/Empty";
	import PopupHalf from "../Layout/PopupHalf";
	export default {
		name: 'UserMultiSend'
		, components: {PopupHalf, Empty}
		, props: ['user', 'shop_info', 'company_fee']
		, data: function(){
			return {

				program: {
					name: '지갑 송금'
					, top: false
					, title: true
					, bottom: false
				}
				, items_send: []
				, items_shop: []
				, item_search: {
					keyword: ''
				}
				, item_shop: {

				}
				, is_on_half: false
				, shop_balance: ''
				, shop_uid: this.$route.params.shop_uid
				, item_last: {

				}
			}
		}
		, computed: {
			total_amount: function(){
				let t = 0
				this.items_send.filter( ( item) => {
					t += Number(item.amount ? item.amount : 0)
				})
				return t
			}
			, is_disabled: function (){
				let t = true

				if(this.items_send.length > 0){
					t = false
				}
				this.items_send.filter( (item) => {
					if(!item.amount){
						t = true
					}
				})

				if(this.total_amount > Number(this.shop_balance)){
					t = true
				}

				return t
			}
		}
		, methods: {

			getData: async function(){
				try{
					this.$bus.$emit('on', true)
					let result = await this.$Axios({
						method: 'get'
						, url: 'user/getShopWithdrawalInfo'
						, data: {
							shop_uid: this.shop_uid
						}
					})
					if(result.success){
						this.shop_balance = result.data.shop_balance
						this.items_account = result.data.account_list
					}else{
						throw result.message
					}
				}catch(e){
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, getSearch: async function(){
				try{
					this.$bus.$emit('on', true)
					let result = await this.$Axios({
						method: 'get'
						, url: 'user/getSearchShop'
						, data: {
							shop_uid: this.shop_uid
							, keyword: this.item_search.keyword
						}
					})
					if(result.success){
						this.items_shop = result.data
						this.is_on_half = true
					}else{
						throw result.message
					}
				}catch(e){
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, setItem: function(item){

				let t = true
				this.items_send.filter( (shop) => {
					if(item.shop_uid == shop.shop_uid){
						t = false
						return
					}
				})
				if(t){
					this.items_send.push(item)
				}

				this.is_on_half = false
			}
			, setLast: function(item){
				this.item_last = item
			}
			, removeItem: function(index){
				this.items_send.splice(index, 1)
			}
			
			, postMultiSend: async function(pin){
				this.$bus.$emit('on', true)

				try {

					const result = await this.$Axios({
						method: 'post'
						, url: 'user/postMultiSend'
						, data: {
							my_shop_uid: this.shop_uid
							, pin: pin
							, items: JSON.stringify(this.items_send)
						}
					})

					if (result.success) {
						this.$bus.$emit('to', { name: 'WalletList'})
					} else {
						this.$bus.$emit('notify', {type: 'error', message: result.message})
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					setTimeout( () => {
						this.$bus.$emit('on', false)
					}, 3000)
				}
			}

			, onPin: function(){
				this.$bus.$off('pinCallback')

				this.$bus.$emit('onPin', {
					type: 'check'
				})

				this.$bus.$on('pinCallback', (call) => {
					console.log('multisend pinCallback', call)
					this.postMultiSend(call)
				})
			}
		}
		, created() {
			this.$bus.$emit('onLoad', this.program)
			this.getData()
		}
		, watch: {
			total_amount: {
				handler: function(call){
					if(call > Number(this.shop_balance)){
						this.$set(this.item_last, 'amount', '')
						this.$bus.$emit('notify', { type: 'error', message: '송금가능금액을 확인하세요'})
					}
				}
			}
		}
	}
</script>